import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { getUser } from "./Redux/Actions/UserActions";
import Loader from "./Component/Loader";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { logEvent } from "firebase/analytics";  


const Home = React.lazy(() => import("./Pages/Home"));
const Login = React.lazy(() => import("./Pages/Auth/Login"));
const Register = React.lazy(() => import("./Pages/Auth/Register"));
const OtpVerify = React.lazy(() => import("./Pages/Auth/OtpVerification"))
const PricingPlans = React.lazy(()=> import("./Pages/PricingPlans"))
const ForgotPassword = React.lazy(() => import("./Pages/Auth/ForgotPassword"));
const Account = React.lazy(() => import("./Pages/Account"));
const Description = React.lazy(() => import("./Pages/Descriptions"));
const Watch = React.lazy(() => import("./Pages/PlayingVideo"));
const Policy = React.lazy(() => import("./Pages/Policy"));
const TvShows = React.lazy(() => import("./Pages/TvShows"));
const Season = React.lazy(() => import("./Pages/Season"));
const Movies = React.lazy(() => import("./Pages/Movie"));
const CategoryPage = React.lazy(() => import("./Pages/CategorySingle"));
const WatchList = React.lazy(() => import("./Pages/WatchLists"));
const DeleteAccount = React.lazy(() => import("./Pages/DeleteAccount"));
const PaymentHistory = React.lazy(() => import("./Component/PaymentHistory"))

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyC7Esg-q1odqTaxL3Zzi3qsujSnH1Cg2aU",
      authDomain: "women2womentv-project.firebaseapp.com",
      projectId: "women2womentv-project",
      storageBucket: "women2womentv-project.firebasestorage.app",
      messagingSenderId: "245755252426",
      appId: "1:245755252426:web:cdc127031dbd646f8d2e33",
      measurementId: "G-WK1B87FWKC"
    };
    // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  logEvent(analytics, "page_view", { page_title: "Main App" });
  logEvent(analytics, "button_click", { button_name: "Get Started" });
    
  }, [])

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<Loader />}>
              <Home />
            </React.Suspense>
          }
        />
        <Route
          path="/home"
          element={
            <React.Suspense fallback={<Loader />}>
              <Home />
            </React.Suspense>
          }
        />
        {/* <Route
          path="/login"
          element={
            <React.Suspense fallback={<Loader />}>
              <Login />
            </React.Suspense>
          }
        /> */}
        {/* <Route
          path="/register"
          element={
            <React.Suspense fallback={<Loader />}>
              <Register />
            </React.Suspense>
          }
        /> */}
        {/* <Route
          path="/verify-otp"
          element={
            <React.Suspense fallback={<Loader/>}>
              <OtpVerify/>
            </React.Suspense>
          }
        /> */}
        {/* <Route
          path="/forgot-password"
          element={
            <React.Suspense fallback={<Loader />}>
              <ForgotPassword />
            </React.Suspense>
          }
        /> */}
        <Route
          path="/account"
          element={
            <React.Suspense fallback={<Loader />}>
              <Account />
            </React.Suspense>
          }
        />
        <Route 
          path="/subscription/plans"
          element={
            <React.Suspense fallback={<Loader/>}>
              <PricingPlans/>
            </React.Suspense>
          }
        />
        <Route
          path="/description/:videoId"
          element={
            <React.Suspense fallback={<Loader />}>
              <Description />
            </React.Suspense>
          }
        />
        <Route
          path="/watch/:videoId"
          element={
            <React.Suspense fallback={<Loader />}>
              <Watch />
            </React.Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <React.Suspense fallback={<Loader/>}>
              <Policy/>
            </React.Suspense>
          }
        />
        <Route
          path="/tvshows"
          element={
            <React.Suspense fallback={<Loader/>}>
              <TvShows/>
            </React.Suspense>
          }        
        />
        <Route
          path="/season/:videoId"
          element={
            <React.Suspense fallback={<Loader/>}>
              <Season/>
            </React.Suspense>
          }
        />
        <Route
          path="/movies"
          element={
            <React.Suspense fallback={<Loader/>}>
              <Movies/>
            </React.Suspense>
          }
        />
        <Route
        path="/category/:categoryId"
        element={
          <React.Suspense fallback={<Loader/>}>
            <CategoryPage/>
          </React.Suspense>
        }
        />
        <Route
          path="/watchlist"
          element={
            <React.Suspense fallback={<Loader/>}>
              <WatchList/>
            </React.Suspense>
          }
        />
        <Route
          path="/account/delete"
          element={
            <React.Suspense fallback={<Loader/>} >
              <DeleteAccount/>
            </React.Suspense>
          }
        />
        <Route 
          path="/account/payment/history"
          element={
            <React.Suspense fallback={<Loader/>}>
              <PaymentHistory/>
            </React.Suspense>
          }
        />
      </Routes>
    </div>
  );
}

export default App;

