import {
  GET_HOME_LIST,
  GET_HOME_LIST_ERROR,
  GET_HOME_LIST_SUCCESS,
  GET_HOME_SLIDER,
  GET_HOME_SLIDER_ERROR,
  GET_HOME_SLIDER_SUCCESS,
  GET_MOVIE_LIST,
  GET_MOVIE_LIST_ERROR,
  GET_MOVIE_LIST_SUCCESS,
  GET_TV_SHOW_LIST,
  GET_TV_SHOW_LIST_SUCCESS,
  GET_TV_SHOW_LIST_ERROR,
  GET_SINGLE_SHOW_LIST,
  GET_SINGLE_SHOW_LIST_SUCCESS,
  GET_SINGLE_SHOW_LIST_ERROR,
  
  GET_MORE_LIKE_THIS_LIST,
  GET_MORE_LIKE_THIS_LIST_SUCCESS,
  GET_MORE_LIKE_THIS_LIST_ERROR,
  GET_SUBSCRIPTION_LIST,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_ERROR,
  GET_PACKAGE_LIST,
  GET_PACKAGE_LIST_SUCCESS,
  GET_PACKAGE_LIST_ERROR,
  GET_SEARCH_RESULTS,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_ERROR,
  GET_WATCH_LIST,
  GET_WATCH_LIST_SUCCESS,
  GET_WATCH_LIST_ERROR,
  GET_WATCH_LIST_ID,
  GET_WATCH_LIST_SUCCESS_ID,
  GET_WATCH_LIST_ERROR_ID,
  ADD_TO_WATCH_LIST,
  ADD_TO_WATCH_LIST_SUCCESS,
  ADD_TO_WATCH_LIST_ERROR,
  SAVE_CONTINUE_WATCHING,
  SAVE_CONTINUE_WATCHING_SUCCESS,
  SAVE_CONTINUE_WATCHING_ERROR,
  FETCH_CONTINUE_WATCHING,
  FETCH_CONTINUE_WATCHING_SUCCESS,
  FETCH_CONTINUE_WATCHING_ERROR


} from "../Constants/DataConstants";

const initialState = {
  slider: [],
  listing: [],
  movielisting: [],
  tvShowListing: [],
  packageListing: [],
  singleTvShowListing: [],
  moreLikeThisListing: [],
  subscriptionListing: [],
  searchResult: [],
  watchListResult: [],
  watchListResult: [],
  watchListById: [],
  addContinueWatching: [],
  loading: {
    slider: false,
    listing: false,
    movielisting: false,
    tvShowListing: false,
    packageListing: false,
    singleTvShowListing: false,
    moreLikeThisListing: false,
    subscriptionListing: false,
    searchResult: false,
    watchListResult: false,
    watchListById: false,
    addToWatchList: false,
    saveContinueWatching: false,
  },
  error: {
    slider: null,
    listing: null,
    movielisting: null,
    tvShowListing: null,
    packageListing: null,
    singleTvShowListing: null,
    moreLikeThisListing: null,
    subscriptionListingnull: null,
    searchResult: null,
    watchListResult: null,
    watchListById: null,
    addToWatchList: null,
    saveContinueWatching: null
  },
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_SLIDER:
      return { ...state, loading: { ...state.loading, slider: true } };
    case GET_HOME_SLIDER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, slider: false },
        slider: action.slider,
      };
    case GET_HOME_SLIDER_ERROR:
      return {
        ...state,
        loading: { ...state.loading, slider: true },
        error: { ...state.error, slider: action.error },
      };

      // Home Listing
    case GET_HOME_LIST:
      return { ...state, loading: { ...state.loading, listing: true } };
    case GET_HOME_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, listing: false },
        listing: action.listing,
      };
    case GET_HOME_LIST_ERROR:
      return {
        ...state,
        loading: { ...state.loading, listing: false },
        error: { ...state.error, listing: action.error },
      };

      // Movie Listing
      case GET_MOVIE_LIST:
        return { ...state, loading: { ...state.loading, movielisting: true} };
      case GET_MOVIE_LIST_SUCCESS:
        return {
          ...state,
          loading: { ...state.loading, movielisting: false },
          movielisting: action.movielisting,
        };
      case GET_MOVIE_LIST_ERROR: 
        return {
          ...state,
        loading: { ...state.loading, movielisting: false },
        error: { ...state.error, movielisting: action.error },
        }

        // TV Show
        case GET_TV_SHOW_LIST:
          return { ...state, loading: {...state.loading, tvShowListing: true} }
        case GET_TV_SHOW_LIST_SUCCESS: 
          return {
            ...state,
            loading: { ...state.loading, tvShowListing: false },
            tvShowListing: action.tvShowListing,
          };
        case GET_TV_SHOW_LIST_ERROR:
          return {
            ...state,
          loading: { ...state.loading, tvShowListing: false },
          error: { ...state.error, tvShowListing: action.error },
          }

          // Packages
          case GET_PACKAGE_LIST:
            return {...state, loading: {...state.loading, packageListing: true} };
          case GET_PACKAGE_LIST_SUCCESS: 
            return {
              ...state,
              loading: { ...state.loading, packageListing: false },
              packageListing: action.packageListing,
            };
          case GET_PACKAGE_LIST_ERROR: 
            return {
              ...state,
          loading: { ...state.loading, packageListing: false },
          error: { ...state.error, packageListing: action.error },
            }

            // Single Tv Show
          case GET_SINGLE_SHOW_LIST:
            return {...state, loading: {...state.loading, singleTvShowListing: true} };
          case GET_SINGLE_SHOW_LIST_SUCCESS: 
            return {
              ...state,
              loading: { ...state.loading, singleTvShowListing: false },
              singleTvShowListing: action.singleTvShowListing,
            };
          case GET_SINGLE_SHOW_LIST_ERROR: 
            return {
              ...state,
              loading: { ...state.loading, singleTvShowListing: false },
              error: { ...state.error, singleTvShowListing: action.error },
              }

            // More Like This
          case GET_MORE_LIKE_THIS_LIST:
            return {...state, loading: {...state.loading, moreLikeThisListing: true} };
          case GET_MORE_LIKE_THIS_LIST_SUCCESS: 
            return {
              ...state,
              loading: { ...state.loading, moreLikeThisListing: false },
              moreLikeThisListing: action.moreLikeThisListing,
            };
          case GET_MORE_LIKE_THIS_LIST_ERROR: 
            return {
              ...state,
              loading: { ...state.loading, moreLikeThisListing: false },
              error: { ...state.error, moreLikeThisListing: action.error },
              }
              // Subscription
          case GET_SUBSCRIPTION_LIST:
            return {...state, loading: {...state.loading, subscriptionListing: true} };
          case GET_SUBSCRIPTION_LIST_SUCCESS: 
            return {
              ...state,
              loading: { ...state.loading, subscriptionListing: false },
              subscriptionListing: action.subscriptionListing,
            };
          case GET_SUBSCRIPTION_LIST_ERROR: 
            return {
              ...state,
              loading: { ...state.loading, subscriptionListing: false },
              error: { ...state.error, subscriptionListing: action.error },
              }

              // Search
          case GET_SEARCH_RESULTS:
            return {...state, loading: {...state.loading, searchResult: true} };
          case GET_SEARCH_RESULTS_SUCCESS: 
            return {
              ...state,
              loading: { ...state.loading, searchResult: false },
              searchResult: action.searchResult,
            };
          case GET_SEARCH_RESULTS_ERROR: 
            return {
              ...state,
              loading: { ...state.loading, searchResult: false },
              error: { ...state.error, searchResult: action.error },
              }

              // watchList
          case GET_WATCH_LIST:
            return {...state, loading: {...state.loading, watchListResult: true} };
          case GET_WATCH_LIST_SUCCESS: 
            return {
              ...state,
              loading: { ...state.loading, watchListResult: false },
              watchListResult: action.watchListResult,
            };
          case GET_WATCH_LIST_ERROR: 
            return {
              ...state,
              loading: { ...state.loading, watchListResult: false },
              error: { ...state.error, watchListResult: action.error },
              }

              // Watchlist By Id
          case GET_WATCH_LIST_ID:
            return {...state, loading: {...state.loading, watchListById: true} };
          case GET_WATCH_LIST_SUCCESS_ID: 
            return {
              ...state,
              loading: { ...state.loading, watchListById: false },
              watchListById: action.watchListById,
            };
          case GET_WATCH_LIST_ERROR_ID: 
            return {
              ...state,
              loading: { ...state.loading, watchListById: false },
              error: { ...state.error, watchListById: action.error },
              }

              // Add to Watchlist
          case ADD_TO_WATCH_LIST:
            return {
              ...state,
              loading: { ...state.loading, addToWatchList: true },
            };

          case ADD_TO_WATCH_LIST_SUCCESS:
            return {
              ...state,
              loading: { ...state.loading, addToWatchList: false },
              watchListResult: [...state.watchListResult, action.addedVideo],
            };

          case ADD_TO_WATCH_LIST_ERROR:
            return {
              ...state,
              loading: { ...state.loading, addToWatchList: false },
              error: { ...state.error, addToWatchList: action.error },
            };
            
            // Continue Watching
            case SAVE_CONTINUE_WATCHING:
            return {
              ...state,
              loading: { ...state.loading, saveContinueWatching: true },
            };

          case SAVE_CONTINUE_WATCHING_SUCCESS:
            return {
              ...state,
              loading: { ...state.loading, saveContinueWatching: false },
              addContinueWatching: [...state.addContinueWatching, action.addedVideo],
            };

          case SAVE_CONTINUE_WATCHING_ERROR:
            return {
              ...state,
              loading: { ...state.loading, saveContinueWatching: false },
              error: { ...state.error, saveContinueWatching: action.error },
            };

    default:
      return state;
  }
};

export default dataReducer;
