import {
  OTP_RESET,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  SEND_OTP,
  SEND_OTP_ERROR,
  SEND_OTP_SUCCESS,
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
} from "../Constants/AuthConstants";

const initialState = {
  token: null,
  loading: false,
  loginError: null,
  signUpError: null,
  resetError: null,
  otpError: null,
  data: null,
  otpSent: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return { ...state, loading: true, loginError: null, token: null };

    case SIGN_IN_SUCCESS:
      return { ...state, loading: false, token: action.token };

    case SIGN_IN_ERROR:
      return { ...state, loading: false, loginError: action.error };

    case SIGN_UP:
      return { ...state, loading: true, signUpError: null, data: null };

    case SIGN_UP_SUCCESS:
      return { ...state, loading: false, data: action.data };

    case SIGN_UP_ERROR:
      return { ...state, loading: false, signUpError: action.error };

    case RESET_PASSWORD:
      return { ...state, loading: true, resetError: null, data: null };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, data: action.data };

    case RESET_PASSWORD_ERROR:
      return { ...state, loading: false, resetError: action.error };

    case SEND_OTP:
      return { ...state, loading: true, loginError: null, token: null };

    case SEND_OTP_SUCCESS:
      return { ...state, loading: false, otpSent: true, otpError: null };

    case SEND_OTP_ERROR:
      return {
        ...state,
        loading: false,
        otpError: action.error,
      };

    case VERIFY_OTP:
      return { ...state, loading: true, loginError: null, token: null };

    case VERIFY_OTP_SUCCESS:
      return { ...state, loading: false, token: action.token };

    case VERIFY_OTP_ERROR:
      return {
        ...state,
        loading: false,
        otpError: action.error,
      };

    case OTP_RESET:
      return { ...state, otpError: null, otpSent: false };

    default:
      return state;
  }
};

export default authReducer;
